enum EventType {
  CHILD_IS_PICKED_UP = "CHILD_IS_PICKED_UP",
  CHILD_LEAVES_ALONE = "CHILD_LEAVES_ALONE",
  CHILD_CAME = "CHILD_CAME",
  AUTHORIZED_PERSON_CAME = "AUTHORIZED_PERSON_CAME",
  CHILD_LEFT = "CHILD_LEFT",
  CHILD_APOLOGISE = "CHILD_APOLOGISE",
  CHILD_TEMPORARILY_LOCATED = "CHILD_TEMPORARILY_LOCATED",
}

export default EventType;
