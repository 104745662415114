export function getLocale(): string {
  const locale = navigator.language || process.env.VUE_APP_I18N_LOCALE || "cs";
  if (!["cs", "sk", "en"].includes(locale)) {
    return process.env.VUE_APP_I18N_FALLBACK_LOCALE;
  }
  if (locale === "sk") {
    return "cs";
  }
  return locale;
}
