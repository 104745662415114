import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import store from "@/store";
import router from "@/router";
import i18n from "@/plugins/i18n";
import qs from "qs";
import _ from "lodash";

export interface FilterQueryParameters {
  page: number;
  itemsPerPage: number;
  filter: string;
  sort: string;
}

export class AllItems implements FilterQueryParameters {
  page: number;
  itemsPerPage: number;
  filter: string;
  sort: string;

  constructor(filter = "", sort = "") {
    this.page = 1;
    this.itemsPerPage = 1_000_000_000;
    this.filter = filter;
    this.sort = sort;
  }
}

export interface ClientOptions {
  withoutErrorSnackbar?: boolean;
  cacheResponse?: boolean;
  throwPath?: boolean;
}

export default class AbstractClient {
  /**
   * HTTP GET method facade
   * @param url GET request URL
   * @param pagination pagination/filter/sort query parameters
   * @param options client options
   * @param config axios request config
   * @protected
   */
  protected static get(url: string, pagination: FilterQueryParameters | null = null, options: ClientOptions = {}, config: AxiosRequestConfig = {}): Promise<AxiosResponse> {
    if (pagination !== null) {
      url += ("?" + qs.stringify(pagination));
    }
    if (options.throwPath === true) {
      throw url;
    }
    if (options.cacheResponse) {
      const cacheItem = store.getters["Cache/getCacheItem"](url);
      if (cacheItem !== null) {
        return new Promise((resolve => {
          resolve(cacheItem.data);
        }));
      }
    }
    return axios.get(AbstractClient.getBaseUrl() + url, config)
      .then((response) => {
        if (options.cacheResponse) {
          store.commit("Cache/setCacheItem", {
            key: url,
            data: response,
          });
        }
        return response;
      })
      .catch((error) => {
        if (options.withoutErrorSnackbar !== true) {
          store.commit("Snackbar/setSnackbar", {
            message: AbstractClient.getErrorMessage(error),
            color: "error",
          });
        }
        throw error;
      });
  }

  /**
   * HTTP POST method facade
   * @param url POST request URL
   * @param data POST request body
   * @param options client options
   * @param config axios request config
   * @protected
   */
  protected static post(url: string, data: any = {}, options: ClientOptions = {}, config: AxiosRequestConfig = {}): Promise<AxiosResponse> {
    return axios.post(AbstractClient.getBaseUrl() + url, data, config)
      .catch((error) => {
        if (options.withoutErrorSnackbar !== true) {
          store.commit("Snackbar/setSnackbar", {
            message: AbstractClient.getErrorMessage(error),
            color: "error",
          });
        }
        throw error;
      });
  }

  /**
   * HTTP PATCH method facade
   * @param url PATCH request URL
   * @param data PATCH request body
   * @param options client options
   * @param config axios request config
   * @protected
   */
  protected static patch(url: string, data: any = {}, options: ClientOptions = {}, config: AxiosRequestConfig = {}): Promise<AxiosResponse> {
    return axios.patch(AbstractClient.getBaseUrl() + url, data, config)
      .catch((error) => {
        if (options.withoutErrorSnackbar !== true) {
          store.commit("Snackbar/setSnackbar", {
            message: AbstractClient.getErrorMessage(error),
            color: "error",
          });
        }
        throw error;
      });
  }

  /**
   * HTTP PUT method facade
   * @param url PUT request URL
   * @param data PUT request body
   * @param options client options
   * @param config axios request config
   * @protected
   */
  protected static put(url: string, data: any = {}, options: ClientOptions = {}, config: AxiosRequestConfig = {}): Promise<AxiosResponse> {
    return axios.put(AbstractClient.getBaseUrl() + url, data, config)
      .catch((error) => {
        if (options.withoutErrorSnackbar !== true) {
          store.commit("Snackbar/setSnackbar", {
            message: AbstractClient.getErrorMessage(error),
            color: "error",
          });
        }
        throw error;
      });
  }

  /**
   * HTTP DELETE method facade
   * @param url DELETE request URL
   * @param options client options
   * @param config axios request config
   * @protected
   */
  protected static delete(url: string, options: ClientOptions = {}, config: AxiosRequestConfig = {}): Promise<AxiosResponse> {
    return axios.delete(AbstractClient.getBaseUrl() + url, config)
      .catch((error) => {
        if (options.withoutErrorSnackbar !== true) {
          store.commit("Snackbar/setSnackbar", {
            message: AbstractClient.getErrorMessage(error),
            color: "error",
          });
        }
        throw error;
      });
  }

  /**
   * Get error message from error response object
   * @param error error response object
   * @private
   */
  public static getErrorMessage(error: any): string {
    const errors = error.response?.data?.errors;
    const status = error.response?.status;
    if (status && [401, 403, 404, 409].includes(status)) {
      if (status === 401) {
        store.commit("User/resetData");
        store.commit("Stripe/resetData");
        store.commit("ApiToken/resetData");
        store.commit("Setting/resetData");
        store.commit("Family/resetData");
        router.push({ name: "home" });
      }
      return i18n.t("error." + status).toString();
    }
    if (errors) {
      return AbstractClient.getFirstError(error.response.data.errors);
    }
    return i18n.t("error.unexpected_error").toString();
  }

  /**
   * Returns first error from object of nested errors
   * @param errors object/array of errors
   * @private
   */
  private static getFirstError(errors: any): string {
    if (_.isArray(errors)) return AbstractClient.getFirstError(_.first(errors));
    if (_.isObject(errors)) return AbstractClient.getFirstError(_.first(_.values(errors)));
    return errors; // string or number
  }

  /**
   * Returns API base URL
   * @private
   */
  private static getBaseUrl(): string {
    const baseUrl = process.env.VUE_APP_API_BASE_URL;
    if (!baseUrl) {
      throw "Environment variable VUE_APP_API_BASE_URL not set";
    }
    return baseUrl;
  }
}
