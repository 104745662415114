export interface StripeState {
  stripeCustomerId: string | null;
  stripePriceId: string | null;
  stripeSubscriptionId: string | null;
}

const Stripe = {
  namespaced: true,
  persistent: true,
  state: {
    stripeCustomerId: null as string | null,
    stripePriceId: null as string | null,
    stripeSubscriptionId: null as string | null,
  } as StripeState,
  getters: {
    getStripeCustomerId: (state: StripeState): string | null => {
      return state.stripeCustomerId;
    },
    getStripePriceId: (state: StripeState): string | null => {
      return state.stripePriceId;
    },
    getStripeSubscriptionId: (state: StripeState): string | null => {
      return state.stripeSubscriptionId;
    },
  },
  mutations: {
    setStripeCustomerId(state: StripeState, stripeCustomerId: string | null): void {
      state.stripeCustomerId = stripeCustomerId;
    },
    setStripePriceId(state: StripeState, stripePriceId: string | null): void {
      state.stripePriceId = stripePriceId;
    },
    setStripeSubscriptionId(state: StripeState, stripeSubscriptionId: string | null): void {
      state.stripeSubscriptionId = stripeSubscriptionId;
    },
    resetData(state: StripeState): void {
      state.stripeCustomerId = null;
      state.stripePriceId = null;
      state.stripeSubscriptionId = null;
    },
  },
};

export default Stripe;
