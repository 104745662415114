enum NotificationType {
  TEMPORARY_LOCATION_REQUESTED = "TEMPORARY_LOCATION_REQUESTED",
  TEMPORARY_LOCATION_ACCEPTED = "TEMPORARY_LOCATION_ACCEPTED",
  NEW_APPLICATION_CREATED = "NEW_APPLICATION_CREATED",
  YOUR_APPLICATION_SUBMITTED = "YOUR_APPLICATION_SUBMITTED",
  YOUR_APPLICATION_PROCESSED = "YOUR_APPLICATION_PROCESSED",
  CHILD_LEFT = "CHILD_LEFT",
  CHILD_CAME = "CHILD_CAME",
  AUTHORIZED_PERSON_CAME = "AUTHORIZED_PERSON_CAME",
  RESTRICTION_ALERT = "RESTRICTION_ALERT",
}

export default NotificationType;
