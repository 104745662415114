import moment from "moment";

export abstract class AbstractNotificationResponse {
  readonly id: number;
  readonly type: string;
  readonly name: string;
  readonly description: string;
  readonly created_at: moment.Moment;
  readonly url: string;
  is_read: boolean;

  protected constructor(data: any) {
    this.id = data.id;
    this.type = data.type;
    this.name = data.name;
    this.description = data.description;
    this.created_at = moment(data.created_at);
    this.url = data.url;
    this.is_read = data.is_read;
  }
}

export class TemporaryLocationRequestedNotificationResponse extends AbstractNotificationResponse {
  readonly temporary_location_id: number;

  constructor(data: any) {
    super(data);
    this.temporary_location_id = data.temporary_location_id;
  }
}

export class TemporaryLocationAcceptedNotificationResponse extends AbstractNotificationResponse {
  readonly temporary_location_id: number;

  constructor(data: any) {
    super(data);
    this.temporary_location_id = data.temporary_location_id;
  }
}

export class NewApplicationCreatedNotificationResponse extends AbstractNotificationResponse {
  readonly application_id: number;

  constructor(data: any) {
    super(data);
    this.application_id = data.application_id;
  }
}

export class YourApplicationSubmittedNotificationResponse extends AbstractNotificationResponse {
  readonly application_id: number;

  constructor(data: any) {
    super(data);
    this.application_id = data.application_id;
  }
}

export class YourApplicationProcessedNotificationResponse extends AbstractNotificationResponse {
  readonly application_id: number;

  constructor(data: any) {
    super(data);
    this.application_id = data.application_id;
  }
}

export class ChildLeftNotificationResponse extends AbstractNotificationResponse {
  readonly child_left_event_id: number;

  constructor(data: any) {
    super(data);
    this.child_left_event_id = data.child_left_event_id;
  }
}

export class ChildCameNotificationResponse extends AbstractNotificationResponse {
  readonly child_came_event_id: number;

  constructor(data: any) {
    super(data);
    this.child_came_event_id = data.child_came_event_id;
  }
}

export class AuthorizedPersonCameNotificationResponse extends AbstractNotificationResponse {
  readonly authorized_person_came_event_id: number;

  constructor(data: any) {
    super(data);
    this.authorized_person_came_event_id = data.authorized_person_came_event_id;
  }
}

export class RestrictionAlertNotificationResponse extends AbstractNotificationResponse {
  readonly restriction_id: number;

  constructor(data: any) {
    super(data);
    this.restriction_id = data.restriction_id;
  }
}

export class UnreadNotificationCountResponse {
  readonly unread_notifications_count: number;

  constructor(data: any) {
    this.unread_notifications_count = data.unread_notifications_count;
  }
}

export class NotificationTypeResponse {
  readonly id: string;
  readonly name: string;
  readonly roles: string[];

  constructor(data: any) {
    this.id = data.id;
    this.name = data.name;
    this.roles = data.roles;
  }
}

export class NotificationTransportTypeResponse {
  readonly id: string;
  readonly name: string;

  constructor(data: any) {
    this.id = data.id;
    this.name = data.name;
  }
}
