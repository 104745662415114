import _Vue from "vue";
import VueI18n from "vue-i18n";
import store from "@/store";
import TranslateResult = VueI18n.TranslateResult;

export function SnackbarPlugin(Vue: typeof _Vue, options?: any): void {
  Vue.prototype.$snackbarSuccess = function(message: string | TranslateResult): void {
    store.commit("Snackbar/setSnackbar", {
      message: message,
      color: "success",
    });
  };
  Vue.prototype.$snackbarError = function(message: string | TranslateResult): void {
    store.commit("Snackbar/setSnackbar", {
      message: message,
      color: "error",
    });
  };
}

export class SnackbarPluginOptions {
  // add stuff
}
