import AbstractClient, { ClientOptions, FilterQueryParameters } from "@/api/AbstractClient";
import { PaginatedResponse } from "@/api/common/types/Responses";
import { ApplicationResponse, UnprocessedApplicationsCountResponse } from "@/api/application/types/Responses";
import { CreateApplicationRequest, ProcessApplicationRequest } from "@/api/application/types/Requests";
import { FamilyResponse } from "@/api/family/types/Responses";

export default class ApplicationClient extends AbstractClient {
  /**
   * Perform create application request
   * @param request create application request
   */
  static createApplication(request: CreateApplicationRequest): Promise<ApplicationResponse> {
    return AbstractClient.post("/api/applications", request)
      .then((response) => new ApplicationResponse(response.data));
  }

  /**
   * Perform get applications request
   * @param schoolYearId school year ID
   * @param pagination pagination/filters/sort configuration
   * @param options client options
   */
  static getApplications(schoolYearId: number, pagination: FilterQueryParameters, options: ClientOptions = {}): Promise<PaginatedResponse<ApplicationResponse>> {
    return AbstractClient.get("/api/applications/by-school-year/" + schoolYearId, pagination, options)
      .then((response) => PaginatedResponse.fromResponseData(ApplicationResponse, response.data));
  }

  /**
   * Perform get my applications request
   * @param pagination pagination/filters/sort configuration
   * @param options client options
   */
  static getMyApplications(pagination: FilterQueryParameters, options: ClientOptions = {}): Promise<PaginatedResponse<ApplicationResponse>> {
    return AbstractClient.get("/api/applications/my", pagination, options)
      .then((response) => PaginatedResponse.fromResponseData(ApplicationResponse, response.data));
  }

  /**
   * Perform get unprocessed applications count request
   * @param schoolYearId school year ID
   */
  static getUnprocessedApplicationsCount(schoolYearId: number): Promise<UnprocessedApplicationsCountResponse> {
    return AbstractClient.get("/api/applications/by-school-year/" + schoolYearId + "/unprocessed-count")
      .then((response) => new UnprocessedApplicationsCountResponse(response.data));
  }

  /**
   * Perform process application request
   * @param applicationId application ID
   * @param request process application request
   */
  static processApplication(applicationId: number, request: ProcessApplicationRequest): Promise<FamilyResponse> {
    return AbstractClient.post("/api/applications/" + applicationId + "/process", request)
      .then((response) => new FamilyResponse(response.data));
  }
}
