import { ChildResponse } from "@/api/child/types/Responses";
import { SchoolClassResponse } from "@/api/school-class/types/Responses";
import { SettingState } from "@/store/modules/Setting.store";

export interface FamilyState {
  selectedChild: ChildResponse | null;
  childrenToSchoolClasses: ChildToSchoolClassState[];
}

export interface ChildToSchoolClassState {
  child: ChildResponse;
  schoolClass: SchoolClassResponse;
}

export interface SelectedChildPayload {
  selectedChild: ChildResponse | null;
}

const Family = {
  namespaced: true,
  persistent: true,
  state: {
    selectedChild: null,
    childrenToSchoolClasses: [],
  } as FamilyState,
  getters: {
    getSelectedChild: (state: FamilyState): ChildResponse | null => {
      return state.selectedChild;
    },
    getChildrenToSchoolClasses: (state: FamilyState): ChildToSchoolClassState[] => {
      return state.childrenToSchoolClasses;
    },
    getChildToSchoolClass: (state: FamilyState) => (child: ChildResponse): ChildToSchoolClassState | null => {
      return state.childrenToSchoolClasses.find((childToSchoolClass: ChildToSchoolClassState) => childToSchoolClass.child.id === child.id) ?? null;
    },
  },
  mutations: {
    setSelectedChild(state: FamilyState, selectedChild: SelectedChildPayload): void {
      state.selectedChild = selectedChild.selectedChild;
    },
    setChildToSchoolClass(state: FamilyState, childToSchoolClass: ChildToSchoolClassState): void {
      const existing = state.childrenToSchoolClasses.find((existing) => existing.child.id === childToSchoolClass.child.id);
      if (existing) {
        const index = state.childrenToSchoolClasses.indexOf(existing);
        state.childrenToSchoolClasses.splice(index, 1);
      }
      state.childrenToSchoolClasses.push(childToSchoolClass);
    },
    resetData(state: FamilyState): void {
      state.selectedChild = null;
      state.childrenToSchoolClasses = [];
    },
  },
};

export default Family;
