import moment from "moment";

export class SchoolYearResponse {
  readonly id: number;
  readonly name: string;
  readonly date_from: moment.Moment;
  readonly date_to: moment.Moment;

  constructor(data: any) {
    this.id = data.id;
    this.name = data.name;
    this.date_from = moment(data.date_from);
    this.date_to = moment(data.date_to);
  }
}
