export interface SnackbarState {
  message: string | null;
  color: string | null;
}

export interface SnackbarPayload {
  message: string;
  color: string;
}

const Snackbar = {
  namespaced: true,
  persistent: true,
  state: {
    message: null,
    color: null,
  } as SnackbarState,
  getters: {
    isVisible: (state: SnackbarState): boolean => {
      return state.message !== null && state.color !== null;
    },
    getMessage: (state: SnackbarState): string | null => {
      return state.message;
    },
    getColor: (state: SnackbarState): string | null => {
      return state.color;
    },
  },
  mutations: {
    setSnackbar(state: SnackbarState, payload: SnackbarPayload): void {
      state.message = payload.message;
      state.color = payload.color;
    },
    resetSnackbar(state: SnackbarState): void {
      state.message = null;
      state.color = null;
    },
  },
};

export default Snackbar;
