import Stripe from "@/store/modules/Stripe.store";
import Vue from "vue";
import Vuex from "vuex";
import VuexPersist from "vuex-persist";
import ApiToken from "@/store/modules/ApiToken.store";
import Cache from "@/store/modules/Cache.store";
import Family from "@/store/modules/Family.store";
import Setting from "@/store/modules/Setting.store";
import Snackbar from "@/store/modules/Snackbar.store";
import Theme from "@/store/modules/Theme.store";
import User from "@/store/modules/User.store";
import Application from "@/store/modules/Application.store";

Vue.use(Vuex);

const vuexPersist = new VuexPersist({
  key: "apux",
  storage: window.localStorage,
});

export default new Vuex.Store({
  plugins: [vuexPersist.plugin],
  modules: {
    ApiToken: ApiToken,
    Application: Application,
    Cache: Cache,
    Family: Family,
    Setting: Setting,
    Snackbar: Snackbar,
    Stripe: Stripe,
    Theme: Theme,
    User: User,
  },
});
