import _ from "lodash";

export class PaginatedResponse<T> {
  readonly items: T[];
  readonly _pagination: Pagination;

  protected constructor(items: any[], pagination: Pagination) {
    this.items = items;
    this._pagination = pagination;
  }

  public static fromResponseData(TCreator: { new(data: any): any }, data: any): PaginatedResponse<any> {
    return new PaginatedResponse(_.toArray(data.items).map((item: any) => new TCreator(item)), data._pagination);
  }

  public static fromResponseDataItems(items: any, pagination: Pagination): PaginatedResponse<any> {
    return new PaginatedResponse(items, pagination);
  }
}

export class Pagination {
  readonly total: number;
  readonly page: number;
  readonly items_per_page: number;

  constructor(data: any) {
    this.total = data.total;
    this.page = data.page;
    this.items_per_page = data.items_per_page;
  }
}

export class CountryResponse {
  readonly code: string;
  readonly name: string;

  constructor(data: any) {
    this.code = data.code;
    this.name = data.name;
  }
}

export class AddressResponse {
  readonly id: number;
  readonly street: string;
  readonly house_number: string;
  readonly city: string;
  readonly postal_code: string;
  readonly country: CountryResponse;

  constructor(data: any) {
    this.id = data.id;
    this.street = data.street;
    this.house_number = data.house_number;
    this.city = data.city;
    this.postal_code = data.postal_code;
    this.country = data.country;
  }
}
