import { UserDetailResponse } from "@/api/user/types/Responses";
import { ChildResponse } from "@/api/child/types/Responses";
import _ from "lodash";

export class FamilyResponse {
  readonly id: number;
  readonly parent_relationship_type: ParentRelationshipTypeResponse;
  readonly authorized_persons: UserDetailResponse[];
  readonly children: ChildResponse[];

  constructor(data: any) {
    this.id = data.id;
    this.parent_relationship_type = new ParentRelationshipTypeResponse(data.parent_relationship_type);
    this.authorized_persons = _.toArray(data.authorized_persons).map((item: any) => new UserDetailResponse(item));
    this.children = _.toArray(data.children).map((item: any) => new ChildResponse(item));
  }
}

export class ParentRelationshipTypeResponse {
  readonly id: string;
  readonly name: string;

  constructor(data: any) {
    this.id = data.id;
    this.name = data.name;
  }
}
