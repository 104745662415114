import Role from "@/enums/Role";
import i18n from "@/plugins/i18n";
import store from "@/store";
import _ from "lodash";
import Vue from "vue";
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from "vue-router";

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: "/",
    name: "home",
    component: () => import(/* webpackChunkName: "Base" */ "@/views/Home.vue"),
  },
  {
    path: "/register",
    name: "registration",
    component: () => import(/* webpackChunkName: "Base" */ "@/views/security/Registration.vue"),
  },
  {
    path: "/register/:hash/activate",
    name: "registerActivate",
    component: () => import(/* webpackChunkName: "Base" */ "@/views/security/RegistrationActivate.vue"),
  },
  {
    path: "/password-recovery",
    name: "passwordRecovery",
    component: () => import(/* webpackChunkName: "Base" */ "@/views/security/PasswordRecovery.vue"),
  },
  {
    path: "/password-recovery/:hash/confirm",
    name: "confirmPasswordRecovery",
    component: () => import(/* webpackChunkName: "Base" */ "@/views/security/ConfirmPasswordRecovery.vue"),
  },
  {
    path: "/verify-email/:hash",
    name: "verifyEmail",
    component: () => import(/* webpackChunkName: "Base" */ "@/views/security/VerifyEmail.vue"),
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import(/* webpackChunkName: "Base" */ "@/views/Dashboard.vue"),
    meta: {
      requiredRoles: [Role.USER],
    },
  },
  {
    path: "/schools",
    name: "schools",
    component: () => import(/* webpackChunkName: "Schools" */ "@/views/school/Schools.vue"),
    meta: {
      requiredRoles: [Role.FOUNDER, Role.ADMIN, Role.SUPER_ADMIN],
    },
  },
  {
    path: "/schools/:schoolId",
    name: "school",
    component: () => import(/* webpackChunkName: "Schools" */ "@/views/school/School.vue"),
    meta: {
      requiredRoles: [Role.FOUNDER, Role.ADMIN, Role.SUPER_ADMIN],
      parentRoute: "schools",
    },
  },
  {
    path: "/schools/:schoolId/users/:userId",
    name: "schoolUser",
    component: () => import(/* webpackChunkName: "Schools" */ "@/views/school/SchoolUser.vue"),
    meta: {
      requiredRoles: [Role.SUPER_ADMIN, Role.FOUNDER, Role.ADMIN],
      parentRoute: "school",
    },
  },
  {
    path: "/schools/:schoolId/years/:schoolYearId",
    name: "schoolYear",
    component: () => import(/* webpackChunkName: "Schools" */ "@/views/school-year/SchoolYear.vue"),
    meta: {
      requiredRoles: [Role.SUPER_ADMIN, Role.FOUNDER, Role.ADMIN],
      parentRoute: "school",
    },
  },
  {
    path: "/applications",
    name: "applications",
    component: () => import(/* webpackChunkName: "Applications" */ "@/views/application/Applications.vue"),
    meta: {
      requiredRoles: [Role.ADMIN],
    },
  },
  {
    path: "/applications/new",
    name: "newApplication",
    component: () => import(/* webpackChunkName: "Applications" */ "@/views/application/NewApplication.vue"),
  },
  {
    path: "/classes",
    name: "schoolClasses",
    component: () => import (/* webpackChunkName: "SchoolClasses" */ "@/views/school-class/SchoolClasses.vue"),
    meta: {
      requiredRoles: [Role.ADMIN, Role.TEACHER],
    },
  },
  {
    path: "/classes/:schoolClassId",
    name: "schoolClass",
    component: () => import (/* webpackChunkName: "SchoolClasses" */ "@/views/school-class/SchoolClass.vue"),
    meta: {
      requiredRoles: [Role.ADMIN, Role.TEACHER],
      parentRoute: "schoolClasses",
    },
  },
  {
    path: "/timetable-records",
    name: "timetableRecords",
    component: () => import(/* webpackChunkName: "Timetables" */ "@/views/timetable/TimetableRecords.vue"),
    meta: {
      requiredRoles: [Role.ADMIN, Role.TEACHER],
    },
  },
  {
    path: "/notifications",
    name: "notifications",
    component: () => import (/* webpackChunkName: "SchoolClasses" */ "@/views/notification/Notifications.vue"),
    meta: {
      requiredRoles: [Role.USER],
    },
  },
  {
    path: "/family-members",
    name: "familyMembers",
    component: () => import (/* webpackChunkName: "AuthorizedPersons" */ "@/views/family/FamilyMembers.vue"),
    meta: {
      requiredRoles: [Role.AUTHORIZED_PERSON],
    },
  },
  {
    path: "/events",
    name: "events",
    component: () => import (/* webpackChunkName: "AuthorizedPersons" */ "@/views/event/Events.vue"),
    meta: {
      requiredRoles: [Role.AUTHORIZED_PERSON],
    },
  },
  {
    path: "/conversation/:conversationId",
    name: "conversation",
    component: () => import (/* webpackChunkName: "AuthorizedPersons" */ "@/views/chat/Conversation.vue"),
    meta: {
      requiredRoles: [Role.USER],
    },
  },
  {
    path: "/subscriptions",
    name: "subscriptions",
    component: () => import (/* webpackChunkName: "AuthorizedPersons" */ "@/views/subscription/Subscriptions.vue"),
    meta: {
      requiredRoles: [Role.FOUNDER],
    },
  },
  {
    path: "/users",
    name: "users",
    component: () => import(/* webpackChunkName: "Users" */ "@/views/user/Users.vue"),
    meta: {
      requiredRoles: [Role.SUPER_ADMIN],
    },
  },
  {
    path: "/users/:userId",
    name: "user",
    component: () => import(/* webpackChunkName: "Users" */ "@/views/user/User.vue"),
    meta: {
      requiredRoles: [Role.SUPER_ADMIN],
      parentRoute: "users",
    },
  },
];

const router = new VueRouter({ routes });

router.beforeEach((to: Route, from: Route, next: NavigationGuardNext<any>): void => {
  // delete local info of unauthenticated user
  if (!store.getters["ApiToken/isAuthenticated"]) {
    store.commit("User/resetData");
    store.commit("ApiToken/resetData");
    store.commit("Setting/resetData");
  }

  // routes without required roles
  const requiredRoles = to?.meta?.requiredRoles;
  if (!_.isArray(requiredRoles) || requiredRoles.length === 0) {
    return next();
  }

  // handle unauthenticated user
  if (!store.getters["ApiToken/isAuthenticated"]) {
    store.commit("Snackbar/setSnackbar", {
      message: i18n.t("error.401"),
      color: "error",
    });
    return next({ name: "home" });
  }

  // check if user has at least one required role
  const userRoles = store.getters["User/getRoles"];
  for (let i = 0; i < userRoles.length; i++) {
    if (requiredRoles.includes(userRoles[i])) {
      return next();
    }
  }

  // handle users without required roles
  store.commit("Snackbar/setSnackbar", {
    message: i18n.t("error.403"),
    color: "error",
  });
  return next({ name: "dashboard" });
});

export default router;
