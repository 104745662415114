import { SchoolResponse } from "@/api/school/types/Responses";
import { SchoolYearResponse } from "@/api/school-year/types/Responses";
import { SchoolClassMinimalResponse, SchoolClassResponse } from "@/api/school-class/types/Responses";

export interface SettingState {
  selectedSchool: SchoolResponse | null;
  selectedSchoolYear: SchoolYearResponse | null;
  selectedSchoolClass: SchoolClassMinimalResponse | null;
}

const SettingStore = {
  namespaced: true,
  persistent: true,
  state: {
    selectedSchool: null,
    selectedSchoolYear: null,
    selectedSchoolClass: null,
  } as SettingState,
  getters: {
    getSelectedSchool: (state: SettingState): SchoolResponse | null => {
      return state.selectedSchool;
    },
    getSelectedSchoolYear: (state: SettingState): SchoolYearResponse | null => {
      return state.selectedSchoolYear;
    },
    getSelectedSchoolClass: (state: SettingState): SchoolClassMinimalResponse | null => {
      return state.selectedSchoolClass;
    },
  },
  mutations: {
    setSelectedSchool(state: SettingState, school: SchoolResponse): void {
      state.selectedSchool = school;
    },
    setSelectedSchoolYear(state: SettingState, schoolYear: SchoolYearResponse): void {
      state.selectedSchoolYear = schoolYear;
    },
    setSelectedSchoolClass(state: SettingState, schoolClass: SchoolClassResponse): void {
      state.selectedSchoolClass = schoolClass;
    },
    resetData(state: SettingState): void {
      state.selectedSchool = null;
      state.selectedSchoolYear = null;
      state.selectedSchoolClass = null;
    },
  },
};

export default SettingStore;
