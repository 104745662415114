import AbstractClient from "@/api/AbstractClient";

export interface PollingServiceItem {
  client: AbstractClient;
  // eslint-disable-next-line @typescript-eslint/ban-types
  clientFunction: Function;
  clientFunctionParameters: any[];
  // eslint-disable-next-line @typescript-eslint/ban-types
  onSuccess: Function;
  // eslint-disable-next-line @typescript-eslint/ban-types
  condition: Function;
}

export default class PollingService {
  private static instance: PollingService | null = null;

  private items: PollingServiceItem[] = [];

  private intervals: number[] = [];

  private readonly timeout: number;

  private constructor() {
    this.timeout = process.env.VUE_APP_POLLING_TIMEOUT;
  }

  public static getInstance(): PollingService {
    if (this.instance === null) {
      this.instance = new PollingService();
    }
    return this.instance;
  }

  public registerItem(item: PollingServiceItem): void {
    this.items.push(item);
  }

  public startPolling(): void {
    this.items.forEach((item: PollingServiceItem) => {
      if (item.condition()) {
        // call client function once at the beginning
        this.clientFunctionCall(item);
        // call client function every VUE_APP_POLLING_TIMEOUT seconds
        const interval = setInterval(() => this.clientFunctionCall(item), this.timeout);
        this.intervals.push(interval);
      }
    });
  }

  public reset(): void {
    this.items = [];
    this.intervals.forEach((interval: number) => {
      clearInterval(interval);
    });
    this.startPolling();
  }

  private clientFunctionCall(item: PollingServiceItem): void {
    item.clientFunction.call(item.client, ...item.clientFunctionParameters)
      .then((response: any) => {
        item.onSuccess(response);
      });
  }
}
