import moment from "moment";
import { SchoolClassResponse } from "@/api/school-class/types/Responses";
import { ChildResponse } from "@/api/child/types/Responses";
import { UserResponse } from "@/api/user/types/Responses";

export class TemporaryLocationResponse {
  readonly id: number;
  readonly date_from: moment.Moment;
  readonly date_to: moment.Moment;
  readonly source_school_class: SchoolClassResponse;
  readonly target_school_class: SchoolClassResponse;
  readonly child: ChildResponse;
  readonly initiated_by: UserResponse;
  readonly accepted_by: UserResponse | null;
  readonly accepted_at: moment.Moment | null;
  readonly temporary_location_state: TemporaryLocationStateResponse;

  constructor(data: any) {
    this.id = data.id;
    this.date_from = moment(data.date_from);
    this.date_to = moment(data.date_to);
    this.source_school_class = new SchoolClassResponse(data.source_school_class);
    this.target_school_class = new SchoolClassResponse(data.target_school_class);
    this.child = new ChildResponse(data.child);
    this.initiated_by = new UserResponse(data.initiated_by);
    this.accepted_by = data.accepted_by ? new UserResponse(data.accepted_by) : null;
    this.accepted_at = data.accepted_at ? moment(data.accepted_at) : null;
    this.temporary_location_state = new TemporaryLocationStateResponse(data.temporary_location_state);
  }
}

export class TemporaryLocationStateResponse {
  readonly id: string;
  readonly name: string;

  constructor(data: any) {
    this.id = data.id;
    this.name = data.name;
  }
}
