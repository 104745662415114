import Vue from "vue";
import VueI18n, { LocaleMessages } from "vue-i18n";

Vue.use(VueI18n);

function loadLocaleMessages(): LocaleMessages {
  const locales = require.context(
    "@/locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i,
  );
  const messages: LocaleMessages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || "cs",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "cs",
  messages: loadLocaleMessages(),
  pluralizationRules: {
    cs: function(choice: number): number {
      if (choice === 0) {
        return 0; // žádné dítě
      }
      if (choice === 1) {
        return 1; // 1 dítě
      }
      if (choice >= 2 && choice <= 4) {
        return 2; // 2, 3, 4 děti
      }
      return 3; // >= 5 dětí
    },
  },
});

