import EventClient from "@/api/events/EventClient";
import { AbstractDepartureEventResponse } from "@/api/events/types/Responses";
import moment from "moment";

export class ChildResponse {
  readonly id: number;
  readonly first_name: string;
  readonly last_name: string;
  readonly full_name: string; // artificial attribute
  readonly initials: string; // artificial attribute
  readonly birthdate: moment.Moment;
  readonly color: string;
  readonly family_id: number;
  readonly school_id: number;
  readonly school_class_ids: number[];

  constructor(data: any) {
    this.id = data.id;
    this.first_name = data.first_name;
    this.last_name = data.last_name;
    this.full_name = data.first_name + " " + data.last_name;
    this.initials = (data.first_name.charAt(0) + data.last_name.charAt(0)).toUpperCase();
    this.birthdate = moment(data.birthdate);
    this.color = data.color;
    this.family_id = data.family_id;
    this.school_id = data.school_id;
    this.school_class_ids = data.school_class_ids;
  }
}

export class ChildAttendanceResponse {
  readonly id: number; // pseudo-artificial attribute for correct data table action behaviour
  readonly child: ChildResponse;
  readonly child_status: ChildStatusResponse;
  readonly departure_event: AbstractDepartureEventResponse | null;

  constructor(data: any) {
    this.id = data.child.id;
    this.child = new ChildResponse(data.child);
    this.child_status = new ChildStatusResponse(data.child_status);
    this.departure_event = data.departure_event !== null ? EventClient.buildDepartureEvent(data.departure_event) : null;
  }
}

export class ChildStatusResponse {
  readonly id: string;
  readonly name: string;
  readonly description: string;

  constructor(data: any) {
    this.id = data.id;
    this.name = data.name;
    this.description = data.description;
  }
}

export class ChildrenCountResponse {
  readonly children_count: number;

  constructor(data: any) {
    this.children_count = data.children_count;
  }
}
