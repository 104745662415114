import AbstractClient, { FilterQueryParameters } from "@/api/AbstractClient";
import { CreateConversationRequest } from "@/api/chat/types/Requests";
import { ConversationResponse, HasUnreadConversationsResponse } from "@/api/chat/types/Responses";
import { PaginatedResponse } from "@/api/common/types/Responses";
import { AxiosResponse } from "axios";

export default class ConversationClient extends AbstractClient {
  /**
   * Perform get conversations request
   * @param pagination pagination/filters/sort configuration
   */
  static getConversations(pagination: FilterQueryParameters): Promise<PaginatedResponse<ConversationResponse>> {
    return AbstractClient.get("/api/chat/conversations", pagination)
      .then((response) => PaginatedResponse.fromResponseData(ConversationResponse, response.data));
  }

  /**
   * Perform get conversation request
   * @param conversationId conversation ID
   */
  static getConversation(conversationId: number): Promise<ConversationResponse> {
    return AbstractClient.get("/api/chat/conversations/" + conversationId)
      .then((response) => new ConversationResponse(response.data));
  }

  /**
   * Perform has unread conversations request
   */
  static hasUnreadConversations(): Promise<HasUnreadConversationsResponse> {
    return AbstractClient.get("/api/chat/conversations/has-unread")
      .then((response) => new HasUnreadConversationsResponse(response.data));
  }

  /**
   * Perform mark conversation as read
   * @param conversationId conversation ID
   */
  static markConversationAsRead(conversationId: number): Promise<AxiosResponse> {
    return AbstractClient.patch("/api/chat/conversations/" + conversationId + "/mark-as-read");
  }

  /**
   * Perform mark conversation as unread
   * @param conversationId conversation ID
   */
  static markConversationAsUnread(conversationId: number): Promise<AxiosResponse> {
    return AbstractClient.patch("/api/chat/conversations/" + conversationId + "/mark-as-unread");
  }

  /**
   * Perform mark all conversations as read
   */
  static markAllConversationsAsRead(): Promise<AxiosResponse> {
    return AbstractClient.patch("/api/chat/conversations/mark-all-as-read");
  }

  /**
   * Perform create conversation request
   * @param request create conversation request
   */
  static createConversation(request: CreateConversationRequest): Promise<ConversationResponse> {
    return AbstractClient.put("/api/chat/conversations", request)
      .then((response) => new ConversationResponse(response.data));
  }
}
