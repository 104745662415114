export interface UserState {
  id: number | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  roles: string[];
  avatarUrl: string | null;
}

export interface UserPayload {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  roles: string[];
  avatarUrl: string | null;
}

const User = {
  namespaced: true,
  persistent: true,
  state: {
    id: null,
    firstName: null,
    lastName: null,
    email: null,
    roles: [],
    avatarUrl: null,
  } as UserState,
  getters: {
    getId: (state: UserState): number | null => {
      return state.id;
    },
    getFullName: (state: UserState): string => {
      return ("" + state.firstName + " " + state.lastName).trim();
    },
    getRoles: (state: UserState): string[] => {
      return state.roles;
    },
    getAvatarUrl: (state: UserState): string | null => {
      return state.avatarUrl;
    },
  },
  mutations: {
    setData(state: UserState, payload: UserPayload): void {
      state.id = payload.id;
      state.firstName = payload.firstName;
      state.lastName = payload.lastName;
      state.email = payload.email;
      state.roles = payload.roles;
      state.avatarUrl = payload.avatarUrl;
    },
    setAvatarUrl(state: UserState, avatarUrl: string | null): void {
      state.avatarUrl = avatarUrl;
    },
    resetData(state: UserState): void {
      state.id = null;
      state.firstName = null;
      state.lastName = null;
      state.email = null;
      state.roles = [];
      state.avatarUrl = null;
    },
  },
};

export default User;
