import { UserResponse } from "@/api/user/types/Responses";
import _ from "lodash";
import moment from "moment";

export class ConversationResponse {
  readonly id: number;
  readonly participations: ConversationParticipationResponse[];
  readonly last_message: MessageResponse | null;

  constructor(data: any) {
    this.id = data.id;
    this.participations = _.toArray(data.participations).map((item: any) => new ConversationParticipationResponse(item));
    this.last_message = data.last_message !== null ? new MessageResponse(data.last_message) : null;
  }

  getMyParticipation(myId: number): ConversationParticipationResponse | null {
    const participation = this.participations.find((participation: ConversationParticipationResponse): boolean => participation.participant.id === myId);
    return participation instanceof ConversationParticipationResponse ? participation : null;
  }
}

export class ConversationParticipationResponse {
  read_at: moment.Moment | null;
  readonly participant: UserResponse;

  constructor(data: any) {
    this.read_at = data.read_at !== null ? moment(data.read_at) : null;
    this.participant = new UserResponse(data.participant);
  }
}

export class MessageResponse {
  readonly id: number;
  readonly content: string;
  readonly created_at: moment.Moment;
  readonly author_id: number;

  constructor(data: any) {
    this.id = data.id;
    this.content = data.content;
    this.created_at = moment(data.created_at);
    this.author_id = data.author_id;
  }
}

export class HasUnreadConversationsResponse {
  readonly has_unread_conversations: boolean;

  constructor(data: any) {
    this.has_unread_conversations = data.has_unread_conversations;
  }
}
