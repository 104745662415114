import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import cs from "vuetify/src/locale/cs";
import en from "vuetify/src/locale/en";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#306388",
      },
      dark: {
        primary: "#306388",
      },
    },
  },
  lang: {
    locales: { cs, en },
    current: process.env.VUE_APP_I18N_LOCALE || "cs",
  },
});
