export interface ApplicationState {
  unprocessedApplicationsCount: number;
  hasUnreadConversations: boolean;
  unreadNotificationsCount: number;
}

const Application = {
  namespaced: true,
  persistent: true,
  state: {
    unprocessedApplicationsCount: 0,
    hasUnreadConversations: false,
    unreadNotificationsCount: 0,
  } as ApplicationState,
  getters: {
    getUnprocessedApplicationsCount: (state: ApplicationState): number => {
      return state.unprocessedApplicationsCount;
    },
    hasUnreadConversations: (state: ApplicationState): boolean => {
      return state.hasUnreadConversations;
    },
    getUnreadNotificationsCount: (state: ApplicationState): number => {
      return state.unreadNotificationsCount;
    },
  },
  mutations: {
    setUnprocessedApplicationsCount(state: ApplicationState, count: number): void {
      state.unprocessedApplicationsCount = count;
    },
    setHasUnreadMessages(state: ApplicationState, has: boolean): void {
      state.hasUnreadConversations = has;
    },
    setUnreadNotificationsCount(state: ApplicationState, count: number): void {
      state.unreadNotificationsCount = count;
    },
  },
};

export default Application;
