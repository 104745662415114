enum Role {
  FOUNDER = "ROLE_FOUNDER",
  ADMIN = "ROLE_ADMIN",
  SUPER_ADMIN = "ROLE_SUPER_ADMIN",
  TEACHER = "ROLE_TEACHER",
  AUTHORIZED_PERSON = "ROLE_AUTHORIZED_PERSON",
  USER = "ROLE_USER",
}

export default Role;
