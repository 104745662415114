import _Vue from "vue";
import store from "@/store";
import Role from "@/enums/Role";

export function SecurityPlugin(Vue: typeof _Vue, options?: any): void {
  Vue.prototype.$isAuthenticated = function(): boolean {
    return store.getters["ApiToken/isAuthenticated"];
  };

  Vue.prototype.$isFounder = function(): boolean {
    return this.$isAuthenticated() && store.getters["User/getRoles"].includes(Role.FOUNDER);
  };

  Vue.prototype.$isAdmin = function(): boolean {
    return this.$isAuthenticated() && store.getters["User/getRoles"].includes(Role.ADMIN);
  };

  Vue.prototype.$isSuperAdmin = function(): boolean {
    return this.$isAuthenticated() && store.getters["User/getRoles"].includes(Role.SUPER_ADMIN);
  };

  Vue.prototype.$isTeacher = function(): boolean {
    return this.$isAuthenticated() && store.getters["User/getRoles"].includes(Role.TEACHER);
  };

  Vue.prototype.$isAuthorizedPerson = function(): boolean {
    return this.$isAuthenticated() && store.getters["User/getRoles"].includes(Role.AUTHORIZED_PERSON);
  };

  Vue.prototype.$isUser = function(): boolean {
    return this.$isAuthenticated() && store.getters["User/getRoles"].includes(Role.USER);
  };
}

export class SecurityPluginOptions {
  // add stuff
}
