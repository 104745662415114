const Environment = {
  isProduction(): boolean {
    return process.env.NODE_ENV === "production";
  },
  isDevelopment() {
    return process.env.NODE_ENV === "development";
  },
};

export { Environment };
