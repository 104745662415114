import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import { Environment } from "@/utils/Environment";

export default function(): void {
  if (Environment.isProduction() && process.env.VUE_APP_SENTRY_DSN) {
    Sentry.init({
      dsn: process.env.VUE_APP_SENTRY_DSN,
      integrations: [new Integrations.BrowserTracing()],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      // tracesSampleRate: 1.0
    });
  }
}
