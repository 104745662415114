import { default as ThemeEnum } from "@/enums/Theme";

export interface ThemeState {
  theme: ThemeEnum;
}

const Theme = {
  namespaced: true,
  persistent: true,
  state: {
    theme: ThemeEnum.AUTO,
  } as ThemeState,
  getters: {
    getTheme: (state: ThemeState): ThemeEnum => {
      return state.theme;
    },
  },
  mutations: {
    setTheme(state: ThemeState, theme: ThemeEnum): void {
      state.theme = theme;
    },
  },
};

export default Theme;
