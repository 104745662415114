import { SchoolResponse } from "@/api/school/types/Responses";
import { SchoolClassMinimalResponse, SchoolClassResponse } from "@/api/school-class/types/Responses";
import { SchoolYearResponse } from "@/api/school-year/types/Responses";
import { ChildResponse } from "@/api/child/types/Responses";
import Role from "@/enums/Role";
import _ from "lodash";

export class UserResponse {
  readonly id: number;
  readonly first_name: string;
  readonly last_name: string;
  readonly full_name: string; // artificial attribute
  readonly email: string | null;
  readonly phone: string | null;
  readonly roles: string[];
  readonly avatar_url: string | null;
  readonly is_active: boolean;

  constructor(data: any) {
    this.id = data.id;
    this.first_name = data.first_name;
    this.last_name = data.last_name;
    this.full_name = data.first_name + " " + data.last_name;
    this.email = data.email;
    this.phone = data.phone;
    this.roles = data.roles;
    this.avatar_url = data.avatar_url;
    this.is_active = data.is_active;
  }

  isFounder(): boolean {
    return this.roles.includes(Role.FOUNDER);
  }

  isAdmin(): boolean {
    return this.roles.includes(Role.ADMIN);
  }

  isTeacher(): boolean {
    return this.roles.includes(Role.TEACHER);
  }

  isAuthorizedPerson(): boolean {
    return this.roles.includes(Role.AUTHORIZED_PERSON);
  }
}

export class UserDetailResponse extends UserResponse {
  readonly founder?: FounderRoleResponse;
  readonly admin?: AdminRoleResponse;
  readonly teacher?: TeacherRoleResponse;
  readonly authorized_person?: AuthorizedPersonRoleResponse;
  readonly setting: SettingResponse;

  constructor(data: any) {
    super(data);
    if (data.founder) {
      this.founder = new FounderRoleResponse(data.founder);
    }
    if (data.admin) {
      this.admin = new AdminRoleResponse(data.admin);
    }
    if (data.teacher) {
      this.teacher = new TeacherRoleResponse(data.teacher);
    }
    if (data.authorized_person) {
      this.authorized_person = new AuthorizedPersonRoleResponse(data.authorized_person);
    }
    this.setting = new SettingResponse(data.setting);
  }

  isFounder(): boolean {
    return this.roles.includes(Role.FOUNDER);
  }

  isAdmin(): boolean {
    return this.roles.includes(Role.ADMIN);
  }

  isTeacher(): boolean {
    return this.roles.includes(Role.TEACHER);
  }

  isAuthorizedPerson(): boolean {
    return this.roles.includes(Role.AUTHORIZED_PERSON);
  }
}

export class FounderRoleResponse {
  readonly schools: SchoolResponse[];
  readonly stripe_customer_id: string;
  readonly stripe_price_id: string | null;
  readonly stripe_subscription_id: string | null;

  constructor(data: any) {
    this.schools = _.toArray(data.schools).map((school: any) => new SchoolResponse(school));
    this.stripe_customer_id = data.stripe_customer_id;
    this.stripe_price_id = data.stripe_price_id;
    this.stripe_subscription_id = data.stripe_subscription_id;
  }
}

export class AdminRoleResponse {
  readonly schools: SchoolResponse[];

  constructor(data: any) {
    this.schools = _.toArray(data.schools).map((school: any) => new SchoolResponse(school));
  }
}

export class TeacherRoleResponse {
  readonly school: SchoolResponse;
  readonly school_classes: SchoolClassResponse[];

  constructor(data: any) {
    this.school = new SchoolResponse(data.school);
    this.school_classes = _.toArray(data.school_classes).map((schoolClass: any) => new SchoolClassResponse(schoolClass));
  }
}

export class AuthorizedPersonRoleResponse {
  readonly family_id: number;
  readonly authorized_person_type: AuthorizedPersonTypeResponse;

  constructor(data: any) {
    this.family_id = data.family_id;
    this.authorized_person_type = new AuthorizedPersonTypeResponse(data.authorized_person_type);
  }
}

export class RoleResponse {
  readonly id: string;
  readonly name: string;

  constructor(data: any) {
    this.id = data.id;
    this.name = data.name;
  }
}

export class AuthorizedPersonTypeResponse {
  readonly id: string;
  readonly name: string;

  constructor(data: any) {
    this.id = data.id;
    this.name = data.name;
  }
}

export class SettingResponse {
  readonly selected_school: SchoolResponse | null;
  readonly selected_school_year: SchoolYearResponse | null;
  readonly selected_school_class: SchoolClassMinimalResponse | null;
  readonly selected_child: ChildResponse | null;
  readonly child_to_school_class_settings: ChildToSchoolClassSettingResponse[];
  readonly notification_settings: NotificationSettingResponse[];

  constructor(data: any) {
    this.selected_school = data.selected_school !== null ? new SchoolResponse(data.selected_school) : null;
    this.selected_school_year = data.selected_school_year !== null ? new SchoolYearResponse(data.selected_school_year) : null;
    this.selected_school_class = data.selected_school_class !== null ? new SchoolClassMinimalResponse(data.selected_school_class) : null;
    this.selected_child = data.selected_child !== null ? new ChildResponse(data.selected_child) : null;
    this.child_to_school_class_settings = _.toArray(data.child_to_school_class_settings).map((item: any) => new ChildToSchoolClassSettingResponse(item));
    this.notification_settings = _.toArray(data.notification_settings).map((item: any) => new NotificationSettingResponse(item));
  }
}

export class ChildToSchoolClassSettingResponse {
  readonly child: ChildResponse;
  readonly school_class: SchoolClassMinimalResponse;

  constructor(data: any) {
    this.child = new ChildResponse(data.child);
    this.school_class = new SchoolClassMinimalResponse(data.school_class);
  }
}

export class NotificationSettingResponse {
  readonly notification_type: string;
  readonly notification_transport_type: string;
  readonly is_allowed: boolean;

  constructor(data: any) {
    this.notification_type = data.notification_type;
    this.notification_transport_type = data.notification_transport_type;
    this.is_allowed = data.is_allowed;
  }
}

export class PinCodeResponse {
  readonly pin_code: string;
  readonly qr_code: string;

  constructor(data: any) {
    this.pin_code = data.pin_code;
    this.qr_code = data.qr_code;
  }
}
