import { SchoolYearResponse } from "@/api/school-year/types/Responses";
import { UserResponse } from "@/api/user/types/Responses";
import _ from "lodash";
import moment from "moment";
import { SchoolResponse } from "@/api/school/types/Responses";

export class ApplicationResponse {
  readonly id: number;
  readonly created_at: moment.Moment;
  readonly processed_at: moment.Moment | null;
  readonly processed_by: UserResponse | null;
  readonly is_processed: boolean;
  readonly school: SchoolResponse;
  readonly school_year: SchoolYearResponse;
  readonly application_children: ApplicationChildResponse[];
  readonly application_authorized_persons: ApplicationAuthorizedPersonResponse[];

  constructor(data: any) {
    this.id = data.id;
    this.created_at = moment(data.created_at);
    this.processed_at = data.processed_at !== null ? moment(data.processed_at) : null;
    this.processed_by = data.processed_by !== null ? new UserResponse(data.processed_by) : null;
    this.is_processed = data.is_processed;
    this.school = new SchoolResponse(data.school);
    this.school_year = new SchoolYearResponse(data.school_year);
    this.application_children = _.toArray(data.application_children).map((item: any) => new ApplicationChildResponse(item));
    this.application_authorized_persons = _.toArray(data.application_authorized_persons).map((item: any) => new ApplicationAuthorizedPersonResponse(item));
  }
}

export class ApplicationChildResponse {
  readonly id: number;
  readonly first_name: string;
  readonly last_name: string;
  readonly full_name: string; // artificial attribute
  readonly birthdate: moment.Moment;
  readonly class: string;

  constructor(data: any) {
    this.id = data.id;
    this.first_name = data.first_name;
    this.last_name = data.last_name;
    this.full_name = data.first_name + " " + data.last_name;
    this.birthdate = moment(data.birthdate);
    this.class = data.class;
  }
}

export class ApplicationAuthorizedPersonResponse {
  readonly id: number;
  readonly first_name: string;
  readonly last_name: string;
  readonly full_name: string; // artificial attribute
  readonly email: string;
  readonly phone: string;

  constructor(data: any) {
    this.id = data.id;
    this.first_name = data.first_name;
    this.last_name = data.last_name;
    this.full_name = data.first_name + " " + data.last_name;
    this.email = data.email;
    this.phone = data.phone;
  }
}

export class UnprocessedApplicationsCountResponse {
  readonly unprocessed_applications_count: number;

  constructor(data: any) {
    this.unprocessed_applications_count = data.unprocessed_applications_count;
  }
}
