import { AddressResponse } from "@/api/common/types/Responses";

export class SchoolResponse {
  readonly id: number;
  readonly name: string;
  readonly ico: string;
  readonly izo: string;
  readonly email: string;
  readonly phone: string;
  readonly address: AddressResponse;

  constructor(data: any) {
    this.id = data.id;
    this.name = data.name;
    this.ico = data.ico;
    this.izo = data.izo;
    this.email = data.email;
    this.phone = data.phone;
    this.address = new AddressResponse(data.address);
  }
}
