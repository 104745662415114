




import ConversationClient from "@/api/chat/ConversationClient";
import { HasUnreadConversationsResponse } from "@/api/chat/types/Responses";
import IndexClient from "@/api/index/IndexClient";
import { AppVersionResponse } from "@/api/index/types/Responses";
import NotificationClient from "@/api/notification/NotificationClient";
import { UnreadNotificationCountResponse } from "@/api/notification/types/Responses";
import Vue from "vue";
import Theme from "@/enums/Theme";
import moment from "moment";
import { getLocale } from "@/utils/Locale";
import PollingService from "@/service/PollingService";
import ApplicationClient from "@/api/application/ApplicationClient";
import { UnprocessedApplicationsCountResponse } from "@/api/application/types/Responses";

export default Vue.extend({
  name: "App",
  methods: {
    fetchAppVersion(): void {
      IndexClient.getAppVersion()
        .then((response: AppVersionResponse) => {
          console.log(`API version: ${response.version}`); // eslint-disable-line no-console
        });
    },
    initPolling(): void {
      const pollingService = PollingService.getInstance();

      // unprocessed applications count
      const schoolYearId = this.$store.getters["Setting/getSelectedSchoolYear"] !== null ? this.$store.getters["Setting/getSelectedSchoolYear"].id : null;
      if (schoolYearId !== null) {
        pollingService.registerItem({
          client: ApplicationClient,
          clientFunction: ApplicationClient.getUnprocessedApplicationsCount,
          clientFunctionParameters: [schoolYearId],
          onSuccess: (response: UnprocessedApplicationsCountResponse): void => {
            this.$store.commit("Application/setUnprocessedApplicationsCount", response.unprocessed_applications_count);
          },
          condition: (): boolean => {
            return this.$isAdmin();
          },
        });
      }

      // has unread messages
      pollingService.registerItem({
        client: ConversationClient,
        clientFunction: ConversationClient.hasUnreadConversations,
        clientFunctionParameters: [],
        onSuccess: (response: HasUnreadConversationsResponse): void => {
          this.$store.commit("Application/setHasUnreadMessages", response.has_unread_conversations);
        },
        condition: (): boolean => {
          return this.$isAuthenticated();
        },
      });

      // unread notifications count
      pollingService.registerItem({
        client: NotificationClient,
        clientFunction: NotificationClient.getUnreadNotificationsCount,
        clientFunctionParameters: [],
        onSuccess: (response: UnreadNotificationCountResponse): void => {
          return this.$store.commit("Application/setUnreadNotificationsCount", response.unread_notifications_count);
        },
        condition: (): boolean => {
          return this.$isAuthenticated();
        },
      });

      pollingService.startPolling();
    },
    initLocales(): void {
      const locale = getLocale();

      // init locale of Vue i18n library
      this.$i18n.locale = locale;

      // init locale of moment.js library
      moment.locale(locale);

      // init locale of Vuetify library
      this.$vuetify.lang.current = locale;
    },
    initTheme(): void {
      // set up listener for preferred browser/OS theme change
      if (window.matchMedia) {
        const mql = window.matchMedia("(prefers-color-scheme: dark)");

        // Chrome, Firefox
        try {
          const vuetify = this.$vuetify;
          const store = this.$store;
          mql.addEventListener("change", function(this: MediaQueryList, event: MediaQueryListEvent): void {
            const theme = store.getters["Theme/getTheme"];
            if (theme === Theme.AUTO) {
              vuetify.theme.dark = event.matches;
            }
          });
        } catch (e) {
          // do nothing
        }

        // Safari
        try {
          const vuetify = this.$vuetify;
          const store = this.$store;
          mql.addListener(function(this: MediaQueryList, event: MediaQueryListEvent): void {
            const theme = store.getters["Theme/getTheme"];
            if (theme === Theme.AUTO) vuetify.theme.dark = event.matches;
          });
        } catch (e) {
          // do nothing
        }
      }

      // watch for chosen theme change
      this.$store.watch(() => this.$store.getters["Theme/getTheme"], () => {
        this.determineTheme();
      });

      // choose theme
      this.determineTheme();
    },
    determineTheme(): void {
      const activeTheme = this.$store.getters["Theme/getTheme"];
      if (activeTheme === Theme.AUTO) {
        if (window.matchMedia) {
          // try to set up active theme by browser/OS preferences
          const mql = window.matchMedia("(prefers-color-scheme: dark)");
          this.$vuetify.theme.dark = mql.matches;
        } else {
          // determine by daytime, 7:00-20:00 light, dark otherwise
          const isDay = moment().hours() >= 7 && moment().hours() <= 20;
          this.$vuetify.theme.dark = !isDay;
        }
      } else {
        // user chose theme in past, let's set it up
        this.$vuetify.theme.dark = activeTheme === Theme.DARK;
      }
    },
  },
  created(): void {
    console.log(`Client version: ${process.env.VUE_APP_VERSION}`); // eslint-disable-line no-console

    this.initLocales();
    this.initTheme();
    this.initPolling();
    this.fetchAppVersion();
  },
});
