import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import vuetify from "@/plugins/vuetify";
import i18n from "@/plugins/i18n";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import VueTapTarget from "vue-tap-target";
import sentry from "@/plugins/sentry";
import axiosInterceptor from "@/axiosInterceptor";
import { SnackbarPlugin } from "@/plugins/snackbar";
import { SecurityPlugin } from "@/plugins/security";

Vue.config.productionTip = false;

sentry();
axiosInterceptor();
Vue.use(SnackbarPlugin);
Vue.use(SecurityPlugin);
Vue.use(VueTapTarget);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
