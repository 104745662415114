import moment from "moment";

export interface ApiTokenState {
  apiToken: string | null;
  expiresAt: string | null;
  isImpersonated: boolean;
  _impersonated: ApiTokenStateImpersonated;
}

export interface ApiTokenStateImpersonated {
  apiToken: string | null;
  expiresAt: string | null;
  isImpersonated: boolean;
}

const ApiToken = {
  namespaced: true,
  persistent: true,
  state: {
    apiToken: null,
    expiresAt: null,
    isImpersonated: false,
    _impersonated: {
      apiToken: null,
      expiresAt: null,
      isImpersonated: false,
    },
  } as ApiTokenState,
  getters: {
    getApiToken: (state: ApiTokenState): string | null => {
      return state._impersonated.apiToken ?? state.apiToken;
    },
    getIsImpersonated: (state: ApiTokenState): boolean => {
      return state._impersonated.isImpersonated ?? state.isImpersonated;
    },
    isAuthenticated: (state: ApiTokenState): boolean => {
      const apiToken = state._impersonated.apiToken ?? state.apiToken;
      const expiresAt = state._impersonated.expiresAt ?? state.expiresAt;
      return apiToken !== null && expiresAt !== null && moment(expiresAt).isAfter(moment());
    },
  },
  mutations: {
    setApiToken(state: ApiTokenState, apiToken: string): void {
      state.apiToken = apiToken;
    },
    setExpiresAt(state: ApiTokenState, expiresAt: string): void {
      state.expiresAt = expiresAt;
    },
    setIsImpersonated(state: ApiTokenState, isImpersonated: boolean): void {
      state.isImpersonated = isImpersonated;
    },
    // impersonated
    setApiTokenImpersonated(state: ApiTokenState, apiToken: string): void {
      state._impersonated.apiToken = apiToken;
    },
    setExpiresAtImpersonated(state: ApiTokenState, expiresAt: string): void {
      state._impersonated.expiresAt = expiresAt;
    },
    setIsImpersonatedImpersonated(state: ApiTokenState, isImpersonated: boolean): void {
      state._impersonated.isImpersonated = isImpersonated;
    },
    // reset & revert
    resetData(state: ApiTokenState): void {
      state.apiToken = null;
      state.expiresAt = null;
      state.isImpersonated = false;
      state._impersonated = {
        apiToken: null,
        expiresAt: null,
        isImpersonated: false,
      };
    },
    resetDataImpersonate(state: ApiTokenState): void {
      state._impersonated = {
        apiToken: null,
        expiresAt: null,
        isImpersonated: false,
      };
    },
  },
};

export default ApiToken;
