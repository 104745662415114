import { UserResponse } from "@/api/user/types/Responses";
import { ChildResponse } from "@/api/child/types/Responses";
import { SchoolYearResponse } from "@/api/school-year/types/Responses";
import _ from "lodash";

export class SchoolClassMinimalResponse {
  readonly id: number;
  readonly name: string;
  readonly schoolYear: SchoolYearResponse;

  constructor(data: any) {
    this.id = data.id;
    this.name = data.name;
    this.schoolYear = new SchoolYearResponse(data.school_year);
  }
}

export class SchoolClassResponse extends SchoolClassMinimalResponse {
  readonly teachers: UserResponse[];
  readonly children: ChildResponse[];

  constructor(data: any) {
    super(data);
    this.teachers = _.toArray(data.teachers).map((teacher: any) => new UserResponse(teacher));
    this.children = _.toArray(data.children).map((child: any) => new ChildResponse(child));
  }
}
