import store from "@/store";
import { Environment } from "@/utils/Environment";
import axios from "axios";
import { getLocale } from "@/utils/Locale";

export default function(): void {
  axios.interceptors.request.use((request) => {
      // send user's language in header
      request.headers.common["Accept-Language"] = getLocale();

      // send API token in header
      if (store.getters["ApiToken/isAuthenticated"]) {
        request.headers.common["X-Auth-Token"] = store.getters["ApiToken/getApiToken"];
      }

      if (Environment.isDevelopment()) {
        request.params = { ...request.params, XDEBUG_SESSION_START: "PHPSTORM" };
      }

      return request;
    },
    (error) => {
      return Promise.reject(error);
    },
  );
  axios.interceptors.response.use((response) => {
      const apiTokenExpiresAt = response.headers["x-auth-token-expires-at"];
      if (apiTokenExpiresAt) {
        store.commit("ApiToken/setExpiresAt", apiTokenExpiresAt);
      }
      return response;
    },
    (error) => {
      return Promise.reject(error);
    },
  );
}
