import AbstractClient, { ClientOptions, FilterQueryParameters } from "@/api/AbstractClient";
import {
  AbstractNotificationResponse,
  AuthorizedPersonCameNotificationResponse,
  ChildCameNotificationResponse,
  ChildLeftNotificationResponse,
  NewApplicationCreatedNotificationResponse, RestrictionAlertNotificationResponse,
  TemporaryLocationAcceptedNotificationResponse,
  TemporaryLocationRequestedNotificationResponse,
  UnreadNotificationCountResponse,
  YourApplicationProcessedNotificationResponse,
  YourApplicationSubmittedNotificationResponse,
} from "@/api/notification/types/Responses";
import { PaginatedResponse } from "@/api/common/types/Responses";
import NotificationType from "@/enums/NotificationType";
import { AxiosResponse } from "axios";
import _ from "lodash";

export default class NotificationClient extends AbstractClient {
  /**
   * Perform get notifications request
   * @param options client options
   */
  static getNotifications(pagination: FilterQueryParameters, options: ClientOptions = {}): Promise<PaginatedResponse<AbstractNotificationResponse>> {
    return AbstractClient.get("/api/in-app-notifications", pagination, options)
      .then((response) => {
        const notifications = _.toArray(response.data.items).map((data: any): AbstractNotificationResponse => NotificationClient.buildNotification(data));
        return PaginatedResponse.fromResponseDataItems(notifications, response.data._pagination);
      });
  }

  /**
   * Perform get unread notifications count request
   */
  static getUnreadNotificationsCount(): Promise<UnreadNotificationCountResponse> {
    return AbstractClient.get("/api/in-app-notifications/unread-count")
      .then((response) => new UnreadNotificationCountResponse(response.data));
  }

  /**
   * Perform mark notification as read
   * @param notificationId notification ID
   */
  static markNotificationAsRead(notificationId: number): Promise<AxiosResponse> {
    return AbstractClient.patch("/api/in-app-notifications/" + notificationId + "/mark-as-read");
  }

  /**
   * Perform mark notification as unread
   * @param notificationId notification ID
   */
  static markNotificationAsUnread(notificationId: number): Promise<AxiosResponse> {
    return AbstractClient.patch("/api/in-app-notifications/" + notificationId + "/mark-as-unread");
  }

  /**
   * Perform mark all notifications as read
   */
  static markAllNotificationsAsRead(): Promise<AxiosResponse> {
    return AbstractClient.patch("/api/in-app-notifications/mark-all-as-read");
  }

  /**
   * Perform delete notification
   */
  static deleteNotification(notificationId: number): Promise<AxiosResponse> {
    return AbstractClient.delete("/api/in-app-notifications/" + notificationId);
  }

  /**
   * Build notification of specific type from response data
   * @param data response data
   * @private
   */
  private static buildNotification(data: any): AbstractNotificationResponse {
    switch (data.type) {
      case NotificationType.NEW_APPLICATION_CREATED:
        return new NewApplicationCreatedNotificationResponse(data);
      case NotificationType.TEMPORARY_LOCATION_REQUESTED:
        return new TemporaryLocationRequestedNotificationResponse(data);
      case NotificationType.TEMPORARY_LOCATION_ACCEPTED:
        return new TemporaryLocationAcceptedNotificationResponse(data);
      case NotificationType.YOUR_APPLICATION_SUBMITTED:
        return new YourApplicationSubmittedNotificationResponse(data);
      case NotificationType.YOUR_APPLICATION_PROCESSED:
        return new YourApplicationProcessedNotificationResponse(data);
      case NotificationType.CHILD_LEFT:
        return new ChildLeftNotificationResponse(data);
      case NotificationType.CHILD_CAME:
        return new ChildCameNotificationResponse(data);
      case NotificationType.AUTHORIZED_PERSON_CAME:
        return new AuthorizedPersonCameNotificationResponse(data);
      case NotificationType.RESTRICTION_ALERT:
        return new RestrictionAlertNotificationResponse(data);
      default:
        throw "Unsupported notification type";
    }
  }
}
