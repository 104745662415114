import { UserResponse } from "@/api/user/types/Responses";
import { ChildResponse } from "@/api/child/types/Responses";
import { TemporaryLocationResponse } from "@/api/temporary-locations/types/Responses";
import EventTemplateType from "@/enums/EventTemplateType";
import _ from "lodash";
import moment from "moment";

export abstract class AbstractEventTemplateResponse {
  readonly id: number;
  readonly type: string;
  readonly name: string;
  readonly date_from: moment.Moment;
  readonly date_to: moment.Moment;
  readonly time: moment.Moment;
  readonly created_by: UserResponse;
  readonly created_for: ChildResponse;
  readonly school_class_id: number;
  readonly message: string | null;
  readonly repeat_on_monday: boolean;
  readonly repeat_on_tuesday: boolean;
  readonly repeat_on_wednesday: boolean;
  readonly repeat_on_thursday: boolean;
  readonly repeat_on_friday: boolean;
  readonly repeat_on_saturday: boolean;
  readonly repeat_on_sunday: boolean;
  readonly is_processing: boolean;
  readonly events_count: number;

  protected constructor(data: any) {
    this.id = data.id;
    this.type = data.type;
    this.name = data.name;
    this.date_from = moment(data.date_from);
    this.date_to = moment(data.date_to);
    this.time = moment(data.time, ["h:m a", "H:m"]);
    this.created_by = new UserResponse(data.created_by);
    this.created_for = new ChildResponse(data.created_for);
    this.school_class_id = data.school_class_id;
    this.message = data.message;
    this.repeat_on_monday = data.repeat_on_monday;
    this.repeat_on_tuesday = data.repeat_on_tuesday;
    this.repeat_on_wednesday = data.repeat_on_wednesday;
    this.repeat_on_thursday = data.repeat_on_thursday;
    this.repeat_on_friday = data.repeat_on_friday;
    this.repeat_on_saturday = data.repeat_on_saturday;
    this.repeat_on_sunday = data.repeat_on_sunday;
    this.is_processing = data.is_processing;
    this.events_count = data.events_count;
  }
}

export class ChildIsPickedUpEventTemplateResponse extends AbstractEventTemplateResponse {
  readonly authorized_persons: UserResponse[];

  constructor(data: any) {
    super(data);
    this.authorized_persons = _.toArray(data.authorized_persons).map((data: any) => new UserResponse(data));
  }
}

export class ChildLeavesAloneEventTemplateResponse extends AbstractEventTemplateResponse {
  constructor(data: any) {
    super(data);
  }
}

export abstract class AbstractEventResponse {
  readonly id: number;
  readonly type: string;
  readonly name: string;
  readonly color: string;
  readonly datetime: moment.Moment;
  readonly is_timed: boolean;
  readonly created_by: UserResponse;
  readonly created_for: ChildResponse;
  readonly school_class_id: number;
  readonly message: string | null;
  readonly is_templated_event: boolean;
  readonly icon: string;

  protected constructor(data: any, icon: string) {
    this.id = data.id;
    this.type = data.type;
    this.name = data.name;
    this.color = data.color;
    this.datetime = moment(data.date + " " + data.time);
    this.is_timed = data.is_timed;
    this.created_by = new UserResponse(data.created_by);
    this.created_for = new ChildResponse(data.created_for);
    this.school_class_id = data.school_class_id;
    this.message = data.message;
    this.is_templated_event = data.is_templated_event;
    this.icon = icon;
  }
}

export abstract class AbstractTemplatedEventResponse extends AbstractEventResponse {
  readonly edited: boolean;
  readonly event_template: AbstractEventTemplateResponse | null = null;

  protected constructor(data: any, icon: string) {
    super(data, icon);
    this.edited = data.edited;
    if (data.event_template !== null) {
      switch (data.event_template.type) {
        case EventTemplateType.CHILD_IS_PICKED_UP:
          this.event_template = new ChildIsPickedUpEventTemplateResponse(data.event_template);
          break;
        case EventTemplateType.CHILD_LEAVES_ALONE:
          this.event_template = new ChildLeavesAloneEventTemplateResponse(data.event_template);
          break;
        default:
          throw "Unsupported event template type";
      }
    }
  }
}

export abstract class AbstractOneTimeEventResponse extends AbstractEventResponse {
  protected constructor(data: any, icon: string) {
    super(data, icon);
  }
}

export class ChildIsPickedUpEventResponse extends AbstractTemplatedEventResponse {
  readonly pin_code: string;
  readonly authorized_persons: UserResponse[];

  constructor(data: any) {
    super(data, "mdi-account-multiple-check");
    this.pin_code = data.pin_code;
    this.authorized_persons = _.toArray(data.authorized_persons).map((data: any) => new UserResponse(data));
  }
}

export class ChildLeavesAloneEventResponse extends AbstractTemplatedEventResponse {
  constructor(data: any) {
    super(data, "mdi-account-check");
  }
}

export class ChildCameEventResponse extends AbstractOneTimeEventResponse {
  constructor(data: any) {
    super(data, "mdi-account-arrow-right");
  }
}

export class AuthorizedPersonCameEventResponse extends AbstractOneTimeEventResponse {
  constructor(data: any) {
    super(data, "mdi-hand-wave-outline");
  }
}

export class ChildLeftEventResponse extends AbstractOneTimeEventResponse {
  constructor(data: any) {
    super(data, "mdi-account-arrow-left");
  }
}

export class ChildApologiseEventResponse extends AbstractOneTimeEventResponse {
  constructor(data: any) {
    super(data, "mdi-emoticon-cry-outline");
  }
}

export class ChildTemporarilyLocatedEventResponse extends AbstractOneTimeEventResponse {
  readonly temporary_location: TemporaryLocationResponse;

  constructor(data: any) {
    super(data, "mdi-map-marker-radius");
    this.temporary_location = new TemporaryLocationResponse(data.temporary_location);
  }
}

export abstract class AbstractDepartureEventResponse {
  readonly id: number;
  readonly type: string;
  readonly name: string;
  readonly color: string;
  readonly datetime: moment.Moment;
  readonly icon: string;

  protected constructor(data: any, icon: string) {
    this.id = data.id;
    this.type = data.type;
    this.name = data.name;
    this.color = data.color;
    this.datetime = moment(data.date + " " + data.time);
    this.icon = icon;
  }
}

export class ChildIsPickedUpDepartureEventResponse extends AbstractDepartureEventResponse {
  constructor(data: any) {
    super(data, "mdi-account-multiple-check");
  }
}

export class ChildLeavesAloneDepartureEventResponse extends AbstractDepartureEventResponse {
  constructor(data: any) {
    super(data, "mdi-account-check");
  }
}
