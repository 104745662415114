import moment from "moment";

export interface CacheState {
  cache: Record<string, any>;
}

export interface CacheItemState {
  data: any;
  expiresAt: string;
}

export interface CacheItemPayload {
  key: string;
  data: any;
}

const Cache = {
  namespaced: true,
  persistent: true,
  state: {
    cache: {},
  } as CacheState,
  getters: {
    getCacheItem: (state: CacheState) => (key: string): CacheItemState | null => {
      const cacheItem = state.cache[key];
      if (cacheItem) {
        if (moment(cacheItem.expiresAt).isAfter(moment())) {
          return cacheItem;
        } else {
          delete state.cache[key];
        }
      }
      return null;
    },
  },
  mutations: {
    setCacheItem(state: CacheState, payload: CacheItemPayload): void {
      state.cache[payload.key] = {
        key: payload.key,
        data: payload.data,
        expiresAt: moment().add(60, "minutes").toISOString(),
      };
    },
  },
};

export default Cache;
