import AbstractClient, { FilterQueryParameters } from "@/api/AbstractClient";
import { PaginatedResponse } from "@/api/common/types/Responses";
import EventType from "@/enums/EventType";
import {
  AbstractDepartureEventResponse,
  AbstractEventResponse,
  AuthorizedPersonCameEventResponse,
  ChildApologiseEventResponse,
  ChildCameEventResponse, ChildIsPickedUpDepartureEventResponse,
  ChildIsPickedUpEventResponse, ChildLeavesAloneDepartureEventResponse,
  ChildLeavesAloneEventResponse,
  ChildLeftEventResponse,
  ChildTemporarilyLocatedEventResponse,
} from "@/api/events/types/Responses";
import {
  CreateAuthorizedPersonCameEventRequest,
  CreateChildApologiseEventRequest,
  CreateChildIsPickedUpEventRequest,
  CreateChildLeavesAloneEventRequest,
  CreateChildLeftEventRequest,
  UpdateChildApologiseEventRequest,
  UpdateChildIsPickedUpEventRequest,
  UpdateChildLeavesAloneEventRequest,
} from "@/api/events/types/Requests";
import { AxiosResponse } from "axios";
import _ from "lodash";

export default class EventClient extends AbstractClient {
  /**
   * Perform get events request
   * @param schoolClassId school class ID
   * @param childId child ID
   * @param pagination pagination/filters/sort configuration
   */
  static getEvents(schoolClassId: number, childId: number, pagination: FilterQueryParameters): Promise<PaginatedResponse<AbstractEventResponse>> {
    return AbstractClient.get("/api/events/by-class/" + schoolClassId + "/by-child/" + childId, pagination)
      .then((response) => {
        const events = _.toArray(response.data.items).map((data: any): AbstractEventResponse => EventClient.buildEvent(data));
        return PaginatedResponse.fromResponseDataItems(events, response.data._pagination);
      });
  }

  /**
   * Perform get events by event template request
   * @param eventTemplateId event template ID
   * @param pagination pagination/filters/sort configuration
   */
  static getEventsByEventTemplate(eventTemplateId: number, pagination: FilterQueryParameters): Promise<PaginatedResponse<AbstractEventResponse>> {
    return AbstractClient.get("/api/events/by-event-template/" + eventTemplateId, pagination)
      .then((response) => {
        const events = _.toArray(response.data.items).map((data: any): AbstractEventResponse => EventClient.buildEvent(data));
        return PaginatedResponse.fromResponseDataItems(events, response.data._pagination);
      });
  }

  /**
   * Perform create "authorized person came" event request
   * @param request create "authorized person came" event request
   */
  static createAuthorizedPersonCameEvent(request: CreateAuthorizedPersonCameEventRequest): Promise<AuthorizedPersonCameEventResponse> {
    return AbstractClient.post("/api/events/authorized-person-came", request)
      .then((response) => new AuthorizedPersonCameEventResponse(response.data));
  }

  /**
   * Perform create "child apologise" event request
   * @param request create "child apologise" event request
   */
  static createChildApologiseEvent(request: CreateChildApologiseEventRequest): Promise<ChildApologiseEventResponse> {
    return AbstractClient.post("/api/events/child-apologise", request)
      .then((response) => new ChildApologiseEventResponse(response.data));
  }

  /**
   * Perform create "child left" event request
   * @param request create "child left" event request
   */
  static createChildLeftEvent(request: CreateChildLeftEventRequest): Promise<ChildLeftEventResponse> {
    return AbstractClient.post("/api/events/child-left", request)
      .then((response) => new ChildLeftEventResponse(response.data));
  }

  /**
   * Perform create "child is picked up" event request
   * @param request create "child is picked up" event request
   */
  static createChildIsPickedUpEvent(request: CreateChildIsPickedUpEventRequest): Promise<ChildIsPickedUpEventResponse> {
    return AbstractClient.post("/api/events/child-is-picked-up", request)
      .then((response) => new ChildIsPickedUpEventResponse(response.data));
  }

  /**
   * Perform create "child leaves alone" event request
   * @param request create "child leaves alone" event request
   */
  static createChildLeavesAloneEvent(request: CreateChildLeavesAloneEventRequest): Promise<ChildLeavesAloneEventResponse> {
    return AbstractClient.post("/api/events/child-leaves-alone", request)
      .then((response) => new ChildLeavesAloneEventResponse(response.data));
  }

  /**
   * Perform update "child apologise" event request
   * @param eventId event ID
   * @param request update "child apologise" event request
   */
  static updateChildApologiseEvent(eventId: number, request: UpdateChildApologiseEventRequest): Promise<ChildApologiseEventResponse> {
    return AbstractClient.patch("/api/events/child-apologise/" + eventId, request)
      .then((response) => new ChildApologiseEventResponse(response.data));
  }

  /**
   * Perform update "child is picked up" event request
   * @param eventId event ID
   * @param request update "child is picked up" event request
   */
  static updateChildIsPickedUpEvent(eventId: number, request: UpdateChildIsPickedUpEventRequest): Promise<ChildIsPickedUpEventResponse> {
    return AbstractClient.patch("/api/events/child-is-picked-up/" + eventId, request)
      .then((response) => new ChildIsPickedUpEventResponse(response.data));
  }

  /**
   * Perform update "child leaves alone" event request
   * @param eventId event ID
   * @param request update "child leaves alone" event request
   */
  static updateChildLeavesAloneEvent(eventId: number, request: UpdateChildLeavesAloneEventRequest): Promise<ChildLeavesAloneEventResponse> {
    return AbstractClient.patch("/api/events/child-leaves-alone/" + eventId, request)
      .then((response) => new ChildLeavesAloneEventResponse(response.data));
  }

  /**
   * Perform delete event request
   * @param eventId event ID
   */
  static deleteEvent(eventId: number): Promise<AxiosResponse> {
    return AbstractClient.delete("/api/events/" + eventId);
  }

  /**
   * Build event of specific type from response data
   * @param data response data
   * @private
   */
  static buildEvent(data: any): AbstractEventResponse {
    switch (data.type) {
      case EventType.CHILD_IS_PICKED_UP:
        return new ChildIsPickedUpEventResponse(data);
      case EventType.CHILD_LEAVES_ALONE:
        return new ChildLeavesAloneEventResponse(data);
      case EventType.CHILD_CAME:
        return new ChildCameEventResponse(data);
      case EventType.AUTHORIZED_PERSON_CAME:
        return new AuthorizedPersonCameEventResponse(data);
      case EventType.CHILD_LEFT:
        return new ChildLeftEventResponse(data);
      case EventType.CHILD_APOLOGISE:
        return new ChildApologiseEventResponse(data);
      case EventType.CHILD_TEMPORARILY_LOCATED:
        return new ChildTemporarilyLocatedEventResponse(data);
      default:
        throw "Unsupported event type";
    }
  }

  /**
   * Build departure event of specific type from response data
   * @param data response data
   * @private
   */
  static buildDepartureEvent(data: any): AbstractDepartureEventResponse {
    switch (data.type) {
      case EventType.CHILD_IS_PICKED_UP:
        return new ChildIsPickedUpDepartureEventResponse(data);
      case EventType.CHILD_LEAVES_ALONE:
        return new ChildLeavesAloneDepartureEventResponse(data);
      default:
        throw "Unsupported event type";
    }
  }
}
